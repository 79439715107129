import { MetaFunction } from "@remix-run/cloudflare";
import { Meta, Links, Outlet, ScrollRestoration, Scripts, LiveReload } from "@remix-run/react";
import Footer from "~/components/layout/footer";
import Header from "~/components/layout/header";
import TransactionNotification from "~/components/transaction-notification";
import RadixProvider from "~/rdt/radixProvider";

export function links() {
  return [
    { rel: "shortcut icon", type: "image/svg+xml", href: "https://defiplaza.net/assets/images/logo/icon.svg" },
    { rel: "mask-icon", sizes: "any", href: "https://defiplaza.net/assets/images/logo/icon.svg", color: "#5D0FC0" },
  ];
}

export const meta: MetaFunction = () => {
  return [{ title: "DefiPlaza on Radix" }];
};

export default function App() {
  return (
    <html lang="en">
      <head>
        <meta charSet="utf-8" />
        <meta name="viewport" content="width=device-width, initial-scale=1" />
        <meta name="theme-color" content="#f7f5f0" media="(prefers-color-scheme: light)" />
        <meta name="theme-color" content="#3d0283" media="(prefers-color-scheme: dark)" />
        <Meta />
        <Links />
      </head>
      <body className="bg-background-500 dark:bg-primary-900 selection:bg-primary-500 selection:text-primary-100 min-h-screen pb-4 text-black dark:text-white">
        <RadixProvider>
          <Header />
          <main className="z-10 mt-5 lg:mt-12">
            <Outlet />
            <TransactionNotification />
            <ScrollRestoration />
          </main>
          <Footer />
        </RadixProvider>

        <Scripts />
      </body>
    </html>
  );
}
