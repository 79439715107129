export default function Logo() {
    return (
      <>
        <svg width="125px" height="30px" viewBox="0 0 125 30" version="1.1" xmlns="http://www.w3.org/2000/svg">
          <title>DefiPlaza Logo</title>
          <g id="1440*900-v2" stroke="none" strokeWidth="1" fill="currentColor" fillRule="evenodd" className="text-primary-700 dark:text-white">
            <g id="v2defiPlaza-1440*900-App-ENTRYErrorNotice:full-all:DEF" transform="translate(-60.000000, -32.000000)">
              <g id="top" transform="translate(60.000000, 27.000000)">
                <g id="logo" transform="translate(0.000000, 5.728349)">
                  <g fill="currentColor" id="Combined-Shape">
                    <path d="M14.2421028,0.0435815259 C14.8550825,-0.0863220313 15.5172703,0.0740826931 16.0100686,0.526064665 L16.0100686,0.526064665 L17.803,2.17 L17.803,28.639 L15.0425673,29.1916407 C14.8313079,29.2338776 14.6149653,29.2416347 14.4018913,29.214945 L14.2428291,29.1884695 Z M10.681,2.99 L10.681,28.447 L7.121,27.705 L7.121,6.332 L10.681,2.99 Z M21.363,5.436 L24.3518558,8.17693771 C24.6282226,8.43041428 24.8249418,8.75369979 24.9248593,9.10778487 L24.9262683,26.1000765 C24.7275579,26.810597 24.1446916,27.3718615 23.3920985,27.5223267 L23.3920985,27.5223267 L21.363,27.928 L21.363,5.436 Z M3.56,9.675 L3.56,26.964 L1.59236038,26.5547678 C0.719357098,26.3730172 0.0791203493,25.638216 0.00681915148,24.7624987 L0,24.596751 L0,13.8832986 C-2.28808867e-15,13.3309547 0.228425604,12.8032024 0.63112339,12.4251571 L0.63112339,12.4251571 L3.56,9.675 Z"></path>
                  </g>
                  <text id="DeFiPlaza" fontFamily="NB_International_Pro" fontSize="24" fontWeight="400" line-spacing="20" letterSpacing="-0.96" className="fill-black dark:fill-slate-200">
                    <tspan x="33" y="24.2716509">
                      defiplaza
                    </tspan>
                  </text>
                </g>
              </g>
            </g>
          </g>
        </svg>
      </>
    );
}