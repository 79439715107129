import { captureRemixErrorBoundaryError, withSentry } from "@sentry/remix";
import { LoaderFunctionArgs, type MetaFunction } from "@remix-run/cloudflare";
import { Links, LiveReload, Meta, Outlet, isRouteErrorResponse, useRouteError } from "@remix-run/react";
import Header from "./components/layout/header";
import { setDbFromContext } from "./lib/db";

import "./styles/app.css";

export function links() {
  return [
    { rel: "shortcut icon", type: "image/svg+xml", href: "https://defiplaza.net/assets/images/logo/icon.svg" },
    { rel: "mask-icon", sizes: "any", href: "https://defiplaza.net/assets/images/logo/icon.svg", color: "#5D0FC0" },
  ];
}

export function loader({ context }: LoaderFunctionArgs) {
  setDbFromContext(context);

  return null;
}

function App() {
   return (
      <Outlet />       
   );
}

export default withSentry(App);

export function ErrorBoundary() {
   const error = useRouteError();

   if (error instanceof Error) {
      
   }

   // when true, this is what used to go to `CatchBoundary`
   if (isRouteErrorResponse(error)) {
      return (
        <html lang="en">
          <head>
            <Meta />
            <Links />
          </head>
          <body className="bg-background-900 oval-gradient selection:bg-primary-300 selection:text-primary-100 min-h-screen text-white">
            <Header />
            <main className="mt-5 lg:mt-12">
              <div className="mx-4 mb-8 lg:mx-auto lg:w-3/4 lg:w-4/5 xl:w-1/2 2xl:w-2/5">
                <h1 className="py-4 text-left text-3xl font-bold text-white lg:p-8 lg:text-4xl">Oops</h1>
                <p>Status: {error.status}</p>
                <p>{error.data.message}</p>
              </div>
            </main>
          </body>
        </html>
      );
   }

   // Don't forget to typecheck with your own logic.
   // Any value can be thrown, not just errors!
   let errorMessage = 'Unknown error';
   captureRemixErrorBoundaryError(error);
   // if (isDefinitelyAnError(error)) {
   //    errorMessage = error.message;
   // }

   return (
     <html lang="en" className="bg-background-900">
       <head>
         <Meta />
         <Links />
       </head>
       <body className="bg-background-900 oval-gradient selection:bg-primary-300 selection:text-primary-100 min-h-screen text-white">
         <Header />
         <main className="mt-5 lg:mt-12">
           <div className="mx-4 mb-8 lg:mx-auto lg:w-3/4 lg:w-4/5 xl:w-1/2 2xl:w-2/5">
             <h1 className="py-4 text-left text-3xl font-bold text-white lg:p-8 lg:text-4xl">An unexpected error occured.</h1>
             <p>It's me, hi, I'm the problem, it's me:</p>
             <pre>{errorMessage}</pre>
           </div>
         </main>
       </body>
     </html>
   );
}