import { BigNumber } from 'bignumber.js'
import { RadixNetwork } from '@radixdlt/radix-dapp-toolkit';
import { SyntheticEvent } from 'react';
import { IGNITION_DEX_COMPONENT_ADDRESS } from './ignition';
import { networkIsStokenet } from './environment';

export let RADIX_GATEWAY_URL ='https://gateway.radix.live';  //'https://radix-gateway01.astrolescent.com';  //'https://gateway.radix.live'; 
export let RADIX_NETWORK_ID: 1 | 2 = RadixNetwork.Mainnet;
export let D1_DATABASE = 'RADIXPLAZA_MAINNET';

export let DAPP_DEFINITION_ACCOUNT_ADDRESS = 'account_rdx12x2a5dft0gszufcce98ersqvsd8qr5kzku968jd50n8w4qyl9awecr';
export let DEX_PACKAGE_ADDRESS = 'package_rdx1p4lnurhaffzjeg3gu0k27g06ngkvxvyuksczmk9k6gqvztfpks8r7l';
export let DEX_COMPONENT_ADDRESS = 'component_rdx1cqy7sq3mxj2whhlqlryy05hzs96m0ajnv23e7j7vanmdwwlccnmz68';
export let QUOTE_TOKEN_RESOURCE_ADDRESS = 'resource_rdx1t5ywq4c6nd2lxkemkv4uzt8v7x7smjcguzq5sgafwtasa6luq7fclq';
export let XRD_RESOURCE_ADDRESS = 'resource_rdx1tknxxxxxxxxxradxrdxxxxxxxxx009923554798xxxxxxxxxradxrd';

export const OLYMPIA_QUOTE_TOKEN_RESOURCE_ADDRESS = 'resource_rdx1thyqj6yn4rmc8awxqhz6d60q9t677tre0ervrqpzfp4fzc20s4cmce';
export const DFP2_BRIDGE_WALLET = 'account_rdx168wdnywhynsxzatmc29c95vet3rpxp9j47arlut9d97n49sxtgd8jl';

export let STAKING_PACKAGE_ADDRESS ='package_rdx1p5a6lavchvac6ufa526trsnfgv0v4dp2q62nydxc5use8gpwr6cl20';
export let STAKING_OWNER_BADGE = 'resource_rdx1n2zhyhf709zpyq0qp53putz285cld98r4zppec8fxag5xka9k6gsll';

export let RESOURCE_PACKAGE = 'package_rdx1pkgxxxxxxxxxresrcexxxxxxxxx000538436477xxxxxxxxxresrce';

// if (networkIsStokenet()) {
// 	RADIX_GATEWAY_URL = 'https://stokenet-gateway.radix.live'; //'https://babylon-stokenet-gateway.radixdlt.com'; //
// 	RADIX_NETWORK_ID = RadixNetwork.Stokenet;
// 	D1_DATABASE = 'RADIXPLAZA_STOKENET';

// 	DAPP_DEFINITION_ACCOUNT_ADDRESS = 'account_tdx_2_12ycsedykz4ke8l09k53y3gn2rs8hne8kens5kg4rn3gup33k0uld76';
// 	DEX_PACKAGE_ADDRESS = 'package_tdx_2_1phk80e02zjymcfu5dn936snr6q5t7e2uupmxjacsm9zqa39dq98qgv';
// 	DEX_COMPONENT_ADDRESS = 'component_tdx_2_1cq4wfmyttuhjvqu8hv8pvham0hfkkkvv4d9dsswrxmkjssgv5j3g5d';
// 	QUOTE_TOKEN_RESOURCE_ADDRESS = 'resource_tdx_2_1tk60e683g333dxrtr9z8hwtzf8rp4ns85yv9j4wkm5y45xy8p0j329';
// 	XRD_RESOURCE_ADDRESS = 'resource_tdx_2_1tknxxxxxxxxxradxrdxxxxxxxxx009923554798xxxxxxxxxtfd2jc';

// 	STAKING_PACKAGE_ADDRESS = 'package_tdx_2_1p5ctt5jmmyh2cvts732yhpqchwu0uhn2rkhmd0j9dzlcssm0mupjk4';
// 	STAKING_OWNER_BADGE = 'resource_tdx_2_1nfyhjxjk4vffmq395lwcyeuletvrgzp9z6nywgndhv2kv92wy24wey';

// 	RESOURCE_PACKAGE = 'package_tdx_2_1pkgxxxxxxxxxresrcexxxxxxxxx000538436477xxxxxxxxxmn4mes';
// }

export const QUOTE_TOKEN_SYMBOL = 'DFP2';
export const QUOTE_TOKEN_NAME = 'DefiPlaza';
export const QUOTE_TOKEN_ICON = '/assets/img/babylon/defiplaza-icon.png';
export const MINIMUM_DFP2_AMOUNT = 500;

export const XRD_ICON = '/assets/img/tokens/xrd-128x128.png';

export const DEFAULT_PAIR_K_IN = 1;
export const DEFAULT_PAIR_K_OUT = 2;
export const DEFAULT_PAIR_FEE = 0.004;
export const DEFAULT_PAIR_FILTER = 0.9995;
export const ROYALTIES_USD = 0.05;
export const ROYALTIES = 0.833333333333;

export const bigZero = new BigNumber(0);
export const oneE18 = new BigNumber(10).pow(18);
export const ROUND_UP = 0;

const numberSizing = {
	6: 'M',
	9: 'B',
	12: 'T',
	15: 'Qa',
	18: 'Qi',
	21: 'Sx',
	24: 'Sp'
}

export function getQuoteToken(dexAddress: string) {
	if (dexAddress == IGNITION_DEX_COMPONENT_ADDRESS) {
		return {
			address: XRD_RESOURCE_ADDRESS,
			symbol: 'XRD',
			name: 'Radix',
			iconUrl: XRD_ICON
		}
	} else if (dexAddress == 'component_rdx1cq34ta4ssvtyxm3vf0l4eleunt7uz9ha5wyzrc965xqafdueadpy4x') {
		return {
			address: XRD_RESOURCE_ADDRESS,
			symbol: 'XRD',
			name: 'Radix',
			iconUrl: XRD_ICON
		}
	}

	return {
		address: QUOTE_TOKEN_RESOURCE_ADDRESS,
		symbol: QUOTE_TOKEN_SYMBOL,
		name: QUOTE_TOKEN_NAME,
		iconUrl: QUOTE_TOKEN_ICON
	}
}

export function shortenAddress(address: string | null): string {
	if (!address) {
		return '';
	}

	return address.substring(0, 7) + '...' + address.substring(address.length - 6);
}

export function roundToDecimals(amount: number, decimals?: number) {
	if (!decimals) {
		decimals = 6;
	}

	const rounded = (+amount).toLocaleString(undefined, { /*minimumSignificantDigits: decimals,*/ maximumSignificantDigits: decimals });

	return rounded;
}

export function roundALR(alr: number | null) {
	if (!alr) {
		return 0;
	}
	if (alr < 100) {
		return Math.round(alr * 10000) / 100
	}
	if (alr < 1000) {
		return Math.round(alr * 1000) / 100
	}

	return prettyValue(alr);
}

export function prettyValue(value: number | string, asObject?: boolean) {
	value = +value;

	const decimals = 4;
	let size = "M";
	let sign = "";
	let prettyValue = value.toFixed(6);

	if (value < 0) {
		sign = "-";
	}

	value = Math.abs(value);

	if (value == 0) {
		prettyValue = "0";
		size = "";
		// } else if (value < 0.0001) {
		// 		prettyValue = value.toFixed();
		// 		size = '';
		// } else if (value < 0.01) {
		// 	prettyValue = value.toFixed(6);
		// 	size = '';
		// } else if (value < 1) {
		// 	prettyValue = value.toFixed(4);
		// 	size = '';
		// } else if (value < 100) {
		// 	prettyValue = value.toFixed(2);
		// 	size = '';
		// } else if (value < 1000) {
		// 	prettyValue = value.toFixed(1);
		// 	size = '';
	} else if (value < 0.01) {
		return sign + prettySmallCurrency(value);
	} else if (value < 100000) {
		return sign + roundToDecimals(value);
		// } else if (value < 100000) {
		// 	prettyValue = (value / 1000).toFixed(1);
		// 	size = 'K';
	} else if (value < 1000000) {
		prettyValue = Math.round(value / 1000).toFixed(2);
		size = "K";
	} else if (value >= 1000000) {
		// switch to exponential for weird edge cases
		// const v = new BigNumber(value);
		// prettyValue = v.toExponential(2);

		const exp = Math.round(value).toString().length - 1;
		const leftOver = exp % 3;
		const exponential = exp - leftOver;

		// console.log(value, exponential)

		const v = new BigNumber(value);
		const e = new BigNumber(10).pow(exponential);
		const small = v.div(e);

		// console.log(small, small.toFixed(2))

		let fixed = 0;
		if (small.lt(10)) {
			fixed = 2;
		} else if (small.lt(100)) {
			fixed = 1;
		}
		prettyValue = small.toFixed(fixed);
		size = numberSizing[exponential] || '🤔';
	}

	return sign + (+prettyValue).toLocaleString() + size;
}

export function prettySmallCurrency(value: number) {
	const str = value.toFixed(18);

	if (value < 0.01) {
		const priceAfterDecimalPoint = str.split(".")[1];
		let countedZeroes = 0;

		for (let i = 0; i < priceAfterDecimalPoint.length; i++) {
			if (priceAfterDecimalPoint[i] === "0") {
				countedZeroes += 1;
			} else {
				break;
			}
		}

		return countedZeroes <= 3 ? `0.${priceAfterDecimalPoint.slice(0, countedZeroes + 4)}` : `0.0…${priceAfterDecimalPoint.slice(countedZeroes, countedZeroes + 4)}`;
	}
}

export function classNames(...classes: string[]) {
	return classes.filter(Boolean).join(' ');
}

export function getIconUrl(url: string | null, size?: number): string {
	if (!size) {
		size = 32;
	}

	if (!url) {
		return '/assets/img/tokens/_missing-icon.png'
	}

	if (url == 'https://assets.radixdlt.com/icons/icon-xrd-32x32.png') {
		return '/assets/img/tokens/xrd-128x128.png';
	}

	if (!url.startsWith('http')) {
		return url;
	}

	return `https://astrolescent.com/cdn-cgi/image/width=${size * 2},height=${size * 2},fit=scale-down,quality=85/${url}`;
}

export function onIconError(evt: SyntheticEvent<HTMLImageElement, Event>) {
	console.log('icon error!')
	evt.target.onError = null;
	(evt.target as HTMLImageElement).src = '/assets/img/tokens/_missing-icon.png';
	return true;
}

export function generateRandomString(length: number) {
	const array = new Uint8Array(length);
	crypto.getRandomValues(array);
	return Array.from(array)
		.map((byte) => byte.toString(16).padStart(2, '0'))
		.join('');
};

export const apiHeaders = {
		"content-type": "application/json;charset=UTF-8",
		"Access-Control-Allow-Origin": "*",
		"Access-Control-Allow-Methods": "GET,HEAD,POST,OPTIONS",
}