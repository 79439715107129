import MagnifyingGlassIcon from "@heroicons/react/20/solid/MagnifyingGlassIcon";
import { LoaderFunctionArgs, json } from "@remix-run/cloudflare";
import { Link, NavLink, Outlet, useLoaderData } from "@remix-run/react";
import { useEffect, useState } from "react";
import PopoverAPY from "~/components/popover-apy";
import { fetchPrices } from "~/lib/astrolescent";
import { setDbFromContext } from "~/lib/db";
import { getDexes } from "~/lib/models/dex";
import { getPairs } from "~/lib/models/pair";
import { classNames, onIconError, prettyValue, getQuoteToken, getIconUrl, roundALR } from "~/lib/util";

type Pair = {
  baseToken: {
    address: string;
    symbol: string;
    name: string;
    icon: string;
  };
  tvl: number;
  apy: number;
};

const doneTypingInterval = 500;

let timeOutId: any = undefined;

export async function loader({ context }: LoaderFunctionArgs) {
  setDbFromContext(context);

  const allPairs = await getPairs();
  const pairs = allPairs.filter((p) => p.Pair.dexAddress !== "component_rdx1cq34ta4ssvtyxm3vf0l4eleunt7uz9ha5wyzrc965xqafdueadpy4x");
  const prices = await fetchPrices();
  const totals = await getDexes();

  pairs.sort((a, b) => {
    // console.log(a.Pair.address, tvl[a.Pair.address]?.usdAmount, b.Pair.address, tvl[b.Pair.address]?.usdAmount);

    if (a.Pair.tvlUSD < b.Pair.tvlUSD) {
      return 1;
    } else if (a.Pair.tvlUSD > b.Pair.tvlUSD) {
      return -1;
    }

    return 0;
  });

  // calc volumes
  const volume24H = pairs.reduce(
    (total, p) => total + (p.Pair.baseVolume24H * (prices[p.Token.address]?.tokenPriceUSD || 0) + p.Pair.quoteVolume24H * prices[getQuoteToken(p.Pair.dexAddress).address]?.tokenPriceUSD || 0) / 2,
    0,
  );

  // calc tvl
  const totalTVL = pairs.reduce((total, p) => total + (p.Pair.tvlUSD || 0), 0);

  return json({
    pairs,
    totalTVL,
    volume24H,
    volumeTotal: totals.reduce((total, d) => total + d.totalVolumeUSD, 0),
    feesTotal: totals.reduce((total, d) => total + d.totalFeesUSD, 0),
  });
}

export default function LiquidityAdd() {
  const { pairs, totalTVL, volume24H, volumeTotal, feesTotal } = useLoaderData<typeof loader>();

  const [displayPairs, setDisplayPairs] = useState(pairs);
  const [searchTerm, setSearchTerm] = useState("");

  useEffect(() => {
    const regExSearch = new RegExp(searchTerm, "i");

    // Reset search and display all tokens
    if (!searchTerm) {
      setDisplayPairs(pairs);
      return;
    }

    // Filter tokens by search term
    const filteredPairs = [];
    for (const pair of pairs) {
      const token = pair.Token;

      if (token.symbol && token.symbol.match(regExSearch)) {
        filteredPairs.push(pair);
        continue;
      }

      if (token.name && token.name.match(regExSearch)) {
        filteredPairs.push(pair);
        continue;
      }

      // if (token.address && token.address.match(regExSearch)) {
      //   filteredPairs.push(pair);
      //   continue;
      // }
    }

    setDisplayPairs(filteredPairs);
  }, [searchTerm]);

  function onSearch(value: string) {
    clearTimeout(timeOutId);

    timeOutId = setTimeout(() => {
      setSearchTerm(value);
    }, doneTypingInterval);
  }

  return (
    <div className="mx-4 mb-6 xl:container xl:mx-auto">
      <div className="flex items-center justify-between">
        <h1 className="py-4 text-left text-3xl font-bold lg:p-8 lg:text-4xl">Pools</h1>
        <Link to="/liquidity/create" className="btn-white-small">
          Create New Pool
        </Link>
      </div>

      <dl className="mb-4 grid grid-cols-1 gap-4 lg:grid-cols-4">
        <div className="dark:bg-background-900 flex grow flex-row items-center justify-between rounded-xl bg-white px-4 py-2 lg:flex-col lg:items-start lg:justify-normal lg:space-y-4 lg:rounded-2xl lg:p-8">
          <dt className="text-primary-800 dark:text-primary-100 truncate font-medium lg:text-sm">Total Value Locked</dt>
          <dd className="font-semibold lg:mt-1 lg:text-3xl">${prettyValue(totalTVL)}</dd>
        </div>
        <div className="dark:bg-background-900 flex grow flex-row items-center justify-between rounded-xl bg-white px-4 py-2 lg:flex-col lg:items-start lg:justify-normal lg:space-y-4 lg:rounded-2xl lg:p-8">
          <dt className="text-primary-800 dark:text-primary-100 truncate font-medium lg:text-sm">24H Volume</dt>
          <dd className="font-semibold lg:mt-1 lg:text-3xl">${prettyValue(volume24H)}</dd>
        </div>
        <div className="dark:bg-background-900 flex grow flex-row items-center justify-between rounded-xl bg-white px-4 py-2 lg:flex-col lg:items-start lg:justify-normal lg:space-y-4 lg:rounded-2xl lg:p-8">
          <dt className="text-primary-800 dark:text-primary-100 truncate font-medium lg:text-sm">Total Trade Volume</dt>
          <dd className="font-semibold lg:mt-1 lg:text-3xl">${prettyValue(volumeTotal)}</dd>
        </div>
        <div className="dark:bg-background-900 flex grow flex-row items-center justify-between rounded-xl bg-white px-4 py-2 lg:flex-col lg:items-start lg:justify-normal lg:space-y-4 lg:rounded-2xl lg:p-8">
          <dt className="text-primary-800 dark:text-primary-100 truncate font-medium lg:text-sm">Total Fees</dt>
          <dd className="font-semibold lg:mt-1 lg:text-3xl">${prettyValue(feesTotal)}</dd>
        </div>
      </dl>

      <section className="lg:items-top flex flex-col-reverse lg:flex-row lg:space-x-4">
        <div className="dark:bg-background-900 mt-4 grow space-y-4 rounded-xl bg-white p-4 lg:mt-0 lg:rounded-2xl lg:p-8">
          <table className="min-w-full divide-y divide-gray-300">
            <thead className="dark:text-primary-100 text-primary-800">
              <tr>
                <th scope="col" className="pl-0 pr-3 text-left text-sm font-semibold lg:py-3.5 lg:pl-4">
                  Pairs
                </th>
                <th scope="col" className="hidden px-3 pr-1 text-left text-sm font-semibold lg:table-cell lg:py-3.5">
                  TVL
                </th>
                <th scope="col" className="hidden px-3 pl-1 text-left text-sm font-semibold lg:table-cell lg:py-3.5"></th>
                <th scope="col" className="hidden px-3 text-left text-sm font-semibold lg:table-cell lg:py-3.5">
                  <PopoverAPY title="7D ALR" timespan="This ALR is based on the data of the last seven days." />
                </th>
                <th scope="col" className="hidden px-3 text-left text-sm font-semibold lg:table-cell lg:py-3.5">
                  <PopoverAPY title="ALR" timespan="This ALR is based on the data since the creation of the pair." />
                </th>
                <th scope="col" className="relative pl-3 pr-0 lg:py-3.5 lg:pr-4"></th>
              </tr>
            </thead>
            <tbody className="lg:divide-primary-200 lg:divide-y">
              <tr>
                <td colSpan={6}>
                  <div className="flex items-center">
                    <MagnifyingGlassIcon className="pointer-events-none mx-2 h-5 w-5 text-black/40" aria-hidden="true" />
                    <input
                      type="search"
                      defaultValue={searchTerm}
                      onChange={(evt) => onSearch(evt.target.value)}
                      autoCorrect="false"
                      onClick={(evt) => evt.stopPropagation()}
                      onFocus={(evt) => evt.stopPropagation()}
                      placeholder="Search..."
                      className="h-10 w-full border-none bg-transparent pr-3 placeholder-neutral-400"
                    />
                  </div>
                </td>
              </tr>
              {displayPairs.map((pair) => {
                const quoteToken = getQuoteToken(pair.Pair.dexAddress);

                return (
                  <>
                    <tr key={pair.Token.address} className={classNames("dark:hover:bg-primary-800 hover:bg-neutral-100")}>
                      <td className="w-full max-w-0 py-4 pl-0 pr-3 font-bold max-sm:pb-2 sm:w-auto sm:max-w-none sm:pl-0 lg:pl-4">
                        <div className="flex items-center">
                          <div className="relative h-8 w-12">
                            <img className="absolute left-4 top-0 h-8 w-8 shrink-0 rounded-full bg-white" src={getIconUrl(quoteToken.iconUrl)} onError={(evt) => onIconError(evt)} alt="" />
                            <img className="absolute left-0 top-0 h-8 w-8 shrink-0 rounded-full bg-white" src={getIconUrl(pair.Token.iconUrl)} onError={(evt) => onIconError(evt)} alt="" />
                          </div>
                          <div className="ml-2 grow lg:ml-4">
                            <span>
                              {pair.Token.symbol} / {quoteToken.symbol}
                            </span>
                          </div>
                        </div>
                      </td>
                      <td className="dark:text-primary-200 hidden px-3 py-4 pr-1 text-sm font-medium text-black md:table-cell">
                        <p className="flex items-center space-x-2">
                          <img className="h-4 w-4 shrink-0 rounded-full bg-white" src={getIconUrl(pair.Token.iconUrl, 16)} onError={(evt) => onIconError(evt)} alt="" />
                          <span>
                            {prettyValue(pair.Pair.baseTVL || 0)} {pair.Token.symbol}
                          </span>
                        </p>
                        <p className="flex items-center space-x-2">
                          <img className="h-4 w-4 shrink-0 rounded-full bg-white" src={getIconUrl(quoteToken.iconUrl, 16)} alt="" />
                          <span>
                            {prettyValue(pair.Pair.quoteTVL || 0)} {quoteToken.symbol}
                          </span>
                        </p>
                      </td>
                      <td className="dark:text-primary-200 hidden px-3 py-4 pl-1 font-medium text-black max-sm:pb-2 md:table-cell">${prettyValue(pair.Pair.tvlUSD || 0)}</td>
                      <td className="dark:text-primary-200 hidden px-3 py-4 pr-1 text-sm font-medium text-black md:table-cell">
                        <p className="flex items-center space-x-2">
                          <img className="h-4 w-4 shrink-0 rounded-full bg-white" src={getIconUrl(pair.Token.iconUrl, 16)} onError={(evt) => onIconError(evt)} alt="" />
                          <span>{roundALR(pair.Pair.baseAPY7D)}%</span>
                        </p>
                        <p className="flex items-center space-x-2">
                          <img className="h-4 w-4 shrink-0 rounded-full bg-white" src={getIconUrl(quoteToken.iconUrl, 16)} alt="" />
                          <span>{roundALR(pair.Pair.quoteAPY7D)}%</span>
                        </p>
                      </td>
                      <td className="dark:text-primary-200 hidden px-3 py-4 pr-1 text-sm font-medium text-black md:table-cell">
                        <p className="flex space-x-2">
                          <img className="h-4 w-4 shrink-0 rounded-full bg-white" src={getIconUrl(pair.Token.iconUrl, 16)} onError={(evt) => onIconError(evt)} alt="" />
                          <span>{roundALR(pair.Pair.baseAPY)}%</span>
                        </p>
                        <p className="flex space-x-2">
                          <img className="h-4 w-4 shrink-0 rounded-full bg-white" src={getIconUrl(quoteToken.iconUrl, 16)} alt="" />
                          <span>{roundALR(pair.Pair.quoteAPY)}%</span>
                        </p>
                      </td>
                      <td className="py-4 pl-3 pr-0 text-right text-sm font-medium max-sm:pb-2 sm:pr-0 lg:pr-4">
                        <NavLink to={`/liquidity/add/${pair.Token.address}?direction=base`} className={({ isActive }) => (isActive ? "btn-white-table" : "btn-primary-table")}>
                          {({ isActive, isPending }) => (
                            <>
                              {isActive ? (
                                <svg className="inline-block size-4" viewBox="2 2 16 16" xmlns="http://www.w3.org/2000/svg">
                                  <path
                                    d="M10.986 5.456a.608.608 0 0 0-.827 0 .564.564 0 0 0 0 .809l3.233 3.16H5.29a.579.579 0 0 0-.584.572.58.58 0 0 0 .584.572h8.102l-3.233 3.16a.564.564 0 0 0 0 .81c.11.107.257.167.414.167.155 0 .302-.06.413-.168l4.072-3.982a.778.778 0 0 0 0-1.119l-4.072-3.98z"
                                    fill="currentColor"
                                    fillRule="nonzero"
                                  ></path>
                                </svg>
                              ) : (
                                "Add"
                              )}
                            </>
                          )}
                        </NavLink>
                        <NavLink to={`/liquidity/remove/${pair.Token.address}/base`} className="btn-primary-table ml-1">
                          {({ isActive, isPending }) => <>{isActive ? "Remove" : "Remove"}</>}
                        </NavLink>
                      </td>
                    </tr>
                    <tr className="border-b-primary-200 border-b md:hidden">
                      <td>
                        <table className="mx-4 my-2 mt-4 w-full">
                          <tr className="mt-2">
                            <td className="dark:text-primary-100 w-1/2 text-sm text-black">TVL</td>
                            <td className="w-1/2">${prettyValue(pair.Pair.tvlUSD || 0)}</td>
                          </tr>
                          <tr></tr>
                          <tr className="dark:text-primary-100 mt-2 text-sm text-black">
                            <td>7D ALR</td>
                            <td>ALR</td>
                          </tr>
                          <tr>
                            <td>
                              <p className="flex items-center space-x-2">
                                <img className="h-4 w-4 shrink-0 rounded-full bg-white" src={getIconUrl(pair.Token.iconUrl, 16)} onError={(evt) => onIconError(evt)} alt="" />
                                <span>{roundALR(pair.Pair.baseAPY7D)}%</span>
                              </p>
                              <p className="flex items-center space-x-2">
                                <img className="h-4 w-4 shrink-0 rounded-full bg-white" src={getIconUrl(quoteToken.iconUrl, 16)} alt="" />
                                <span>{roundALR(pair.Pair.quoteAPY7D)}%</span>
                              </p>
                            </td>
                            <td>
                              <p className="flex items-center space-x-2">
                                <img className="h-4 w-4 shrink-0 rounded-full bg-white" src={getIconUrl(pair.Token.iconUrl, 16)} onError={(evt) => onIconError(evt)} alt="" />
                                <span>{roundALR(pair.Pair.baseAPY)}%</span>
                              </p>
                              <p className="flex items-center space-x-2">
                                <img className="h-4 w-4 shrink-0 rounded-full bg-white" src={getIconUrl(quoteToken.iconUrl, 16)} alt="" />
                                <span>{roundALR(pair.Pair.quoteAPY)}%</span>
                              </p>
                            </td>
                          </tr>
                        </table>
                      </td>
                      <td>&nbsp;</td>
                    </tr>
                  </>
                );
              })}
            </tbody>
          </table>
        </div>

        <Outlet />
      </section>
    </div>
  );
}
